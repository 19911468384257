import React, { useState, useEffect, useContext } from "react";
import { Form, Col, Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import { UserContext } from "../../context/UserContext";

import FormValidation from "../../form/validation/FormValidation";

import { ProgramSubscriptions } from "../../../constants/ProgramSubscriptions";

export default function MissingRemittancesHistoryForm(props) {
  const userContext = useContext(UserContext);

  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );

  const [searchText, setSearchText] = useState();

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations, loaded]);

  useEffect(() => {
    setLoaded(false);
  }, [userContext.locations]);

  function handleFormSubmit(event) {
    event.preventDefault();

    submitForm();
  }

  function submitForm() {
    let parameters = {
      selectedLocations: selectedLocations,
      searchText: searchText,
      fromDate: fromDate,
      toDate: toDate,
    };
    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      props.handleFormSubmit({
        fromDate: fromDate,
        toDate: toDate,
        searchText: searchText,
        locations: selectedLocations.join(","),
      });
    }
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="justify-content-start spacer_bottom-padding-oneline">
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
            programSubscription={ProgramSubscriptions.Rec}
          />
        </Form.Group>

        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Form.Group>
      </Row>
    </Form>
  );
}
